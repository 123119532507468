import React from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import logo1 from "../assets/cropped-ANZ.png";
import logo2 from "../assets/cropped-arc-logo-rev-MAIN-IMAGE.jpg";
import logo3 from "../assets/cropped-Canon.jpg";
import logo4 from "../assets/cropped-Ergon_aus_logo.jpg";
import logo5 from "../assets/cropped-IAITAMlogoGlobe.jpg";
import logo6 from "../assets/cropped-ITIL.png";
import logo7 from "../assets/cropped-mercycare-og-image.png";
import logo8 from "../assets/cropped-Police.png";
import logo9 from "../assets/cropped-Puma-Logo.png";
import logo10 from "../assets/cropped-VGW.jpeg";

const PartnerSection: React.FC = () => {
  const partners = [
    {
      id: 1,
      logo: logo1,
    },
    {
      id: 2,
      logo: logo2,
    },
    {
      id: 3,
      logo: logo3,
    },
    {
      id: 4,
      logo: logo4,
    },
    {
      id: 5,
      logo: logo5,
    },
    {
      id: 6,
      logo: logo6,
    },
    {
      id: 7,
      logo: logo7,
    },
    {
      id: 8,
      logo: logo8,
    },
    {
      id: 9,
      logo: logo9,
    },
    {
      id: 10,
      logo: logo10,
    },
  ];

  return (
    <motion.section
      className="py-8 sm:py-12 lg:py-10 bg-black-50"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
    >
      <div className="container mx-auto px-4 md:px-10 lg:px-14 xl:px-28 2xl:px-44">
        <motion.div className="max-w-7xl mx-auto">
          {/* Section Header */}
          <motion.div className="flex flex-col items-center mb-14 sm:mb-10">
            <motion.h2 className="text-lg sm:text-2xl lg:text-3xl font-serif font-semibold text-blue-900 mb-4 sm:mb-0 text-center">
              Trusted by Leading Universities and Organizations
            </motion.h2>
          </motion.div>

          {/* Swiper for Trusted Partners */}
          <Swiper
            className="mySwiper"
            modules={[Pagination, Autoplay]}
            spaceBetween={5}
            slidesPerView={6}
            pagination={{ clickable: true, el: ".custom-pagination" }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            breakpoints={{
              640: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1024: { slidesPerView: 4 },
              1280: { slidesPerView: 6 },
            }}
          >
            {partners.map((partner) => (
              <SwiperSlide key={partner.id}>
                <div className="flex justify-center">
                  <img
                    src={partner.logo}
                    alt={`Partner ${partner.id}`}
                    className="h-16 w-32 object-contain"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Pagination Dots */}
          <div className="custom-pagination flex justify-center mt-4" />
        </motion.div>
      </div>

      <style>{`
        .swiper-button-next,
        .swiper-button-prev {
          height: 30px; /* Adjust the height as needed */
          width: 30px; /* Adjust the width as needed */
          color: #4CA6E8; /* Change color if needed */
          font-weight: bold; /* Make the button text bold */
        }
        .swiper-button-next:after,
        .swiper-button-prev:after {
          font-size: 20px; /* Adjust the icon size */
          font-weight: bold; /* Make the icon bold */
        }
        /* Hide navigation buttons on mobile */
        @media (max-width: 640px) {
          .swiper-button-next,
          .swiper-button-prev {
            display: none; /* Hide navigation buttons */
          }
        }
        .custom-pagination {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .custom-pagination .swiper-pagination-bullet {
          background: #4CA6E8; /* Change to your desired color */
          width: 12px;
          height: 12px;
          border-radius: 50%;
          margin: 0 5px;
        }
        .custom-pagination .swiper-pagination-bullet-active {
          background: #0056b3; /* Change to your desired active color */
        }
      `}</style>
    </motion.section>
  );
};

export default PartnerSection;
