export interface Course {
  id: number;
  category: string;
  title: string;
  description: string;
  duration: string;
  level: string;
  link: string;
}

export const coursesData: Course[] = [
  {
    id: 1,
    category: "Agile Management",
    title: "Agile & Scrum",
    description: "Learn Agile and Scrum fundamentals.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083928602629?aff=oddtdtcreator",
  },
  {
    id: 2,
    category: "Agile Management",
    title: "Agile for Product Owner",
    description: "Role of a Product Owner in Agile.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1084000066379?aff=oddtdtcreator",
  },
  {
    id: 3,
    category: "Agile Management",
    title: "Agile Scrum Master",
    description: "Become a certified Scrum Master.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083929946649?aff=oddtdtcreator",
  },
  {
    id: 4,
    category: "Agile Management",
    title: "AgileScrum Foundation",
    description: "Intro to Agile Scrum principles.",
    duration: "2 Days",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1086708326859?aff=oddtdtcreator",
  },
  {
    id: 5,
    category: "Business Improvement & Marketing",
    title: "Business Case Writing",
    description: "Write effective business cases.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1084000808599?aff=oddtdtcreator",
  },
  {
    id: 6,
    category: "Business Improvement & Marketing",
    title: "Business Ethics",
    description: "Explore ethical business issues.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1084000878809?aff=oddtdtcreator",
  },
  {
    id: 7,
    category: "Business Improvement & Marketing",
    title: "Business Etiquette",
    description: "Master professional business etiquette.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083929114159?aff=oddtdtcreator",
  },
  {
    id: 8,
    category: "Business Improvement & Marketing",
    title: "Business Finance Basics",
    description: "Basic knowledge of finance principles.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1082929594569?aff=oddtdtcreator",
  },
  {
    id: 9,
    category: "Business Improvement & Marketing",
    title: "Business Process Analysis & Design",
    description: "Analyze and design efficient processes.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086708507399?aff=oddtdtcreator",
  },
  {
    id: 10,
    category: "Business Improvement & Marketing",
    title: "Business Writing",
    description: "Enhance your business writing skills.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083807139329?aff=oddtdtcreator",
  },
  {
    id: 11,
    category: "Business Improvement & Marketing",
    title: "Internet Marketing Fundamentals",
    description: "Intro to online marketing strategies.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083834792039?aff=oddtdtcreator",
  },
  {
    id: 12,
    category: "Business Improvement & Marketing",
    title: "IoT - Business Perspectives",
    description: "Business applications of IoT.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1084000908899?aff=oddtdtcreator",
  },
  {
    id: 13,
    category: "Soft Skills",
    title: "Anger Management",
    description: "Manage and control anger effectively.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1082931751019?aff=oddtdtcreator",
  },
  {
    id: 14,
    category: "Soft Skills",
    title: "Communication Skills",
    description: "Enhance verbal and non-verbal skills.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1082955923319?aff=oddtdtcreator",
  },
  {
    id: 15,
    category: "Soft Skills",
    title: "Leadership Skills",
    description: "Develop skills to inspire teams.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1082896164579?aff=oddtdtcreator",
  },
  {
    id: 16,
    category: "Data Management & Power BI",
    title: "Microsoft Power BI",
    description: "Intro to data visualization with Power BI.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083834340689?aff=oddtdtcreator",
  },
  {
    id: 17,
    category: "Data Management & Power BI",
    title: "Power BI Dashboard and Data Analysis",
    description: "Create dashboards and analyze data.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086728828179?aff=oddtdtcreator",
  },
  {
    id: 18,
    category: "Project Management",
    title: "Project Management Basics",
    description: "Fundamentals of project management.",
    duration: "2 Days",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083929224489?aff=oddtdtcreator",
  },
  {
    id: 19,
    category: "IT Security",
    title: "Cyber Security",
    description: "Understand the fundamentals of cyber security.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083929174339?aff=oddtdtcreator",
  },
  {
    id: 20,
    category: "Business Improvement & Marketing",
    title: "Digital Marketing",
    description: "Learn digital marketing strategies.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083807129299?aff=oddtdtcreator",
  },
  {
    id: 21,
    category: "Management Skills",
    title: "Performance Management",
    description: "Manage and evaluate team performance.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083807129299?aff=oddtdtcreator",
  },
  {
    id: 22,
    category: "Management Skills",
    title: "Supply Chain Management",
    description: "Fundamentals of managing supply chains.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083928702929?aff=oddtdtcreator",
  },
  {
    id: 23,
    category: "Professional Skills",
    title: "Stakeholder Management",
    description: "Manage relationships with key stakeholders.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086697113319?aff=oddtdtcreator",
  },
  {
    id: 24,
    category: "Management Skills",
    title: "Time Management",
    description: "Techniques to manage time effectively.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1082923125219?aff=oddtdtcreator",
  },
  {
    id: 25,
    category: "Management Skills",
    title: "Building High Performing Teams",
    description: "Skills to lead high-performing teams.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086676120529?aff=oddtdtcreator",
  },
  {
    id: 26,
    category: "Professional Skills",
    title: "Customer Service Essentials",
    description: "Fundamentals of excellent customer service.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083799957849?aff=oddtdtcreator",
  },
  {
    id: 27,
    category: "Management Skills",
    title: "Crisis Management",
    description: "Manage effectively during crises.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086676150619?aff=oddtdtcreator",
  },
  {
    id: 28,
    category: "Soft Skills",
    title: "Workplace Diversity & Inclusion",
    description: "Promote diversity and inclusion at work.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1082973345429?aff=oddtdtcreator",
  },
  {
    id: 29,
    category: "Management Skills",
    title: "Essential Management Skills",
    description: "Foundational skills for effective management.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1082925121189?aff=oddtdtcreator",
  },
  {
    id: 30,
    category: "Construction Quality Management",
    title: "Quality Management",
    description: "Techniques of quality management.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083838071849?aff=oddtdtcreator",
  },
  {
    id: 31,
    category: "Software and Technology",
    title: "AI and Machine Learning",
    description: "Intro to AI and machine learning.",
    duration: "3 Days",
    level: "Intermediate",
    link: "/courses/ai-machine-learning", // link N/A
  },
  {
    id: 32,
    category: "Software and Technology",
    title: "AI Powered Marketing",
    description: "How AI can enhance marketing strategies.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086697043109?aff=oddtdtcreator",
  },
  {
    id: 33,
    category: "Project Management",
    title: "Microsoft Project",
    description: "Manage projects using Microsoft Project.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086728818149?aff=oddtdtcreator",
  },
  {
    id: 34,
    category: "Professional Skills",
    title: "10 Soft Skills You Need",
    description: "Master the top 10 soft skills for success.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083775404409?aff=oddtdtcreator",
  },
  {
    id: 35,
    category: "Professional Skills",
    title: "Emotional Intelligence",
    description: "Develop emotional intelligence for better interactions.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1082891921889?aff=oddtdtcreator",
  },
  {
    id: 36,
    category: "Professional Skills",
    title: "Coaching Skills for Managers",
    description: "Learn coaching skills for effective management.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083834862249?aff=oddtdtcreator",
  },
  {
    id: 37,
    category: "Professional Skills",
    title: "Professional Development Essentials",
    description: "Skills for ongoing professional growth.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1086728838209?aff=oddtdtcreator",
  },
  {
    id: 38,
    category: "Professional Skills",
    title: "Recruitment Skills",
    description: "Effective recruitment and talent acquisition.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083928432119?aff=oddtdtcreator",
  },
  {
    id: 39,
    category: "Management Skills",
    title: "Account Management",
    description: "Manage client accounts and relationships.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086676100469?aff=oddtdtcreator",
  },
  {
    id: 40,
    category: "Business Improvement & Marketing",
    title: "Professional Selling Skills",
    description: "Master advanced selling techniques.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083834410899?aff=oddtdtcreator",
  },
  {
    id: 41,
    category: "Professional Skills",
    title: "Proposal Writing",
    description: "Create effective proposals that win clients.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083835203269?aff=oddtdtcreator",
  },
  {
    id: 42,
    category: "Management Skills",
    title: "Managing Performance",
    description: "Manage and enhance team performance.",
    duration: "1 Day",
    level: "Advanced",
    link: "https://www.eventbrite.com/e/1083835173179?aff=oddtdtcreator",
  },
  {
    id: 43,
    category: "IT Security",
    title: "Cyber Security Overview",
    description: "Foundational understanding of cyber security.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083929555479?aff=oddtdtcreator",
  },
  {
    id: 44,
    category: "Soft Skills",
    title: "Preparing for Job Interviews",
    description: "Techniques to prepare for job interviews.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083929535419?aff=oddtdtcreator",
  },
  {
    id: 45,
    category: "Data Management & Power BI",
    title: "Microsoft Power BI for Business Users",
    description: "Leverage Power BI for data analysis.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086697303889?aff=oddtdtcreator",
  },
  {
    id: 46,
    category: "Management Skills",
    title: "Performance Management",
    description: "Manage and evaluate team performance.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083807129299?aff=oddtdtcreator",
  },
  {
    id: 47,
    category: "Management Skills",
    title: "Supply Chain Management",
    description: "Fundamentals of managing supply chains.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083928702929?aff=oddtdtcreator",
  },
  {
    id: 48,
    category: "Professional Skills",
    title: "Stakeholder Management",
    description: "Manage relationships with key stakeholders.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086697113319?aff=oddtdtcreator",
  },
  {
    id: 49,
    category: "Management Skills",
    title: "Time Management",
    description: "Techniques to manage time effectively.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1082923125219?aff=oddtdtcreator",
  },
  {
    id: 50,
    category: "Management Skills",
    title: "Building High Performing Teams",
    description: "Skills to lead high-performing teams.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086676120529?aff=oddtdtcreator",
  },
  {
    id: 51,
    category: "Professional Skills",
    title: "Customer Service Essentials",
    description: "Fundamentals of excellent customer service.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083799957849?aff=oddtdtcreator",
  },
  {
    id: 52,
    category: "Management Skills",
    title: "Crisis Management",
    description: "Manage effectively during crises.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086676150619?aff=oddtdtcreator",
  },
  {
    id: 53,
    category: "Soft Skills",
    title: "Workplace Diversity & Inclusion",
    description: "Promote diversity and inclusion at work.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1082973345429?aff=oddtdtcreator",
  },
  {
    id: 54,
    category: "Management Skills",
    title: "Essential Management Skills",
    description: "Foundational skills for effective management.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1082925121189?aff=oddtdtcreator",
  },
  {
    id: 55,
    category: "Construction Quality Management",
    title: "Quality Management",
    description: "Techniques of quality management.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083838071849?aff=oddtdtcreator",
  },
  {
    id: 56,
    category: "Software and Technology",
    title: "AI and Machine Learning",
    description: "Intro to AI and machine learning.",
    duration: "3 Days",
    level: "Intermediate",
    link: "/courses/ai-machine-learning", // link N/A
  },
  {
    id: 57,
    category: "Software and Technology",
    title: "AI Powered Marketing",
    description: "How AI can enhance marketing strategies.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086697043109?aff=oddtdtcreator",
  },
  {
    id: 58,
    category: "Project Management",
    title: "Microsoft Project",
    description: "Manage projects using Microsoft Project.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086728818149?aff=oddtdtcreator",
  },
  {
    id: 59,
    category: "Professional Skills",
    title: "10 Soft Skills You Need",
    description: "Master the top 10 soft skills for success.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083775404409?aff=oddtdtcreator",
  },
  {
    id: 60,
    category: "Professional Skills",
    title: "Emotional Intelligence",
    description: "Develop emotional intelligence for better interactions.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1082891921889?aff=oddtdtcreator",
  },
  {
    id: 61,
    category: "Professional Skills",
    title: "Coaching Skills for Managers",
    description: "Learn coaching skills for effective management.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083834862249?aff=oddtdtcreator",
  },
  {
    id: 62,
    category: "Professional Skills",
    title: "Professional Development Essentials",
    description: "Skills for ongoing professional growth.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1086728838209?aff=oddtdtcreator",
  },
  {
    id: 63,
    category: "Professional Skills",
    title: "Recruitment Skills",
    description: "Effective recruitment and talent acquisition.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083928432119?aff=oddtdtcreator",
  },
  {
    id: 64,
    category: "Management Skills",
    title: "Account Management",
    description: "Manage client accounts and relationships.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086676100469?aff=oddtdtcreator",
  },
  {
    id: 65,
    category: "Business Improvement & Marketing",
    title: "Professional Selling Skills",
    description: "Master advanced selling techniques.",
    duration: "2 Days",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083834410899?aff=oddtdtcreator",
  },
  {
    id: 66,
    category: "Professional Skills",
    title: "Proposal Writing",
    description: "Create effective proposals that win clients.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083835203269?aff=oddtdtcreator",
  },
  {
    id: 67,
    category: "Management Skills",
    title: "Managing Performance",
    description: "Manage and enhance team performance.",
    duration: "1 Day",
    level: "Advanced",
    link: "https://www.eventbrite.com/e/1083835173179?aff=oddtdtcreator",
  },
  {
    id: 68,
    category: "IT Security",
    title: "Cyber Security Overview",
    description: "Foundational understanding of cyber security.",
    duration: "1 Day",
    level: "Beginner",
    link: "https://www.eventbrite.com/e/1083929555479?aff=oddtdtcreator",
  },
  {
    id: 69,
    category: "Soft Skills",
    title: "Preparing for Job Interviews",
    description: "Techniques to prepare for job interviews.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1083929535419?aff=oddtdtcreator",
  },
  {
    id: 70,
    category: "Data Management & Power BI",
    title: "Microsoft Power BI for Business Users",
    description: "Leverage Power BI for data analysis.",
    duration: "1 Day",
    level: "Intermediate",
    link: "https://www.eventbrite.com/e/1086697303889?aff=oddtdtcreator",
  },
];

export {};
