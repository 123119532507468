import React from "react";
import SwiperSlider from "../components/swiper-slide";
import HeroSection from "../components/hero-section";
import ABOUTSection from "../components/about-section";
import PopularSection from "../components/popular-section";
import InstructorSection from "../components/instructor-card";
import TestimonialsSection from "../components/testimonials-card";
import FooterSection from "../components/footer-section";
import Navbar from "../components/navbar-section";
import Tagline from "../components/tagline-section";
import PartnerSection from "../components/partner-section";
import { FaWhatsapp } from "react-icons/fa";

const Home: React.FC = () => {
  // Inline CSS for Pulse Animation
  const pulseAnimation = {
    animation: "pulse 1s infinite",
  };

  // Explicitly type the style object
  const whatsappButtonStyle: React.CSSProperties = {
    position: "fixed",
    bottom: "24px",
    right: "24px",
    backgroundColor: "#25d366",
    color: "#fff",
    padding: "10px",
    borderRadius: "50%",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
    zIndex: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s ease",
    ...pulseAnimation, // Add animation styles
  };

  const keyframes = `
    @keyframes pulse {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
      }
      70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 20px rgba(37, 211, 102, 0);
      }
      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
      }
    }
  `;

  return (
    <div className="overflow-x-hidden">
      {/* Keyframes for animation */}
      <style>{keyframes}</style>

      {/* Sections */}
      <Navbar />
      <Tagline />
      <SwiperSlider />
      <PartnerSection />
      <HeroSection />
      <ABOUTSection />
      <PopularSection />
      <TestimonialsSection />
      <InstructorSection />
      <FooterSection />

      {/* WhatsApp Button with Pulse Animation */}
      <a
        href="https://wa.me/14696669332?text=Hi!%20I%27d%20like%20to%20know%20more%20about%20your%20programs"
        style={whatsappButtonStyle}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat on WhatsApp"
      >
        <FaWhatsapp size={30} />
      </a>
    </div>
  );
};

export default Home;
