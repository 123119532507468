import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination, EffectFade } from "swiper/modules";
import type { Swiper as SwiperInstance } from "swiper"; // Import Swiper type
import image1 from "../assets/curosel_1.jpeg";
import image2 from "../assets/curosel_2.jpg";
import image3 from "../assets/curosel_3.jpeg";
import { motion } from "framer-motion";

const SwiperSlider: React.FC = () => {
  const slides = [
    {
      imageUrl: image1,
      heading: "Empower Your Future",
      paragraph: "Transform your potential with MountSkills.",
    },
    {
      imageUrl: image2,
      heading: "Tailored Training for Tomorrow's Leaders",
      paragraph: "Shape your leadership journey with customized programs.",
    },
    {
      imageUrl: image3,
      heading: "Elevate Your Leadership Skills",
      paragraph: "Join us and prepare for a successful tomorrow!",
    },
  ];

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // Define text slide-in animation variants
  const slideAnimation = (direction: "top" | "bottom") => ({
    hidden: {
      opacity: 0,
      y: direction === "top" ? -50 : 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  });

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay, EffectFade]}
      spaceBetween={50}
      slidesPerView={1}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      pagination={{
        clickable: true,
        el: ".swiper-pagination",
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      effect="fade"
      className="w-full aspect-[16/9] sm:aspect-[2/1] md:aspect-[21/9] relative"
      onSlideChange={(swiper: SwiperInstance) =>
        setCurrentSlideIndex(swiper.activeIndex)
      }
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index} className="relative w-full h-full">
          {/* Background Image */}
          <img
            src={slide.imageUrl}
            alt={slide.heading}
            className="absolute inset-0 w-full h-full object-cover"
          />
          {/* Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          {/* Text Content with Animations */}
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4 text-white">
            <motion.div
              key={`heading-${currentSlideIndex}`}
              initial="hidden"
              animate={currentSlideIndex === index ? "visible" : "hidden"}
              variants={slideAnimation("top")}
            >
              <h2 className="text-[1.75rem] sm:text-[2.5rem] md:text-[3rem] lg:text-[3.75rem] font-serif font-bold mb-6 leading-tight tracking-wide">
                {slide.heading}
              </h2>
            </motion.div>
            <motion.div
              key={`paragraph-${currentSlideIndex}`}
              initial="hidden"
              animate={currentSlideIndex === index ? "visible" : "hidden"}
              variants={slideAnimation("bottom")}
            >
              <p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] lg:text-[1.25rem] font-serif max-w-[600px] leading-relaxed">
                {slide.paragraph}
              </p>
            </motion.div>
          </div>
        </SwiperSlide>
      ))}
      <div className="swiper-button-prev !text-white !hidden sm:!flex"></div>

      <div className="swiper-button-next !text-white !hidden sm:!flex"></div>
      <div className="swiper-pagination !bottom-2 sm:!bottom-4"></div>
    </Swiper>
  );
};

export default SwiperSlider;
