import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Slide1 from "../assets/slide.jpg";

const ABOUTSection: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  // Simulate loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500); // Adjust timing as needed
    return () => clearTimeout(timer);
  }, []);

  const handleImageLoad = () => setImageLoaded(true);

  const fullText = [
    "Welcome to our learning platform! We are dedicated to providing high-quality education and transformative learning experiences.",
    "Our team of experts is committed to helping students achieve their goals through innovative teaching methods and personalized support.",
    "With over a decade of experience in online education, we've helped thousands of students master new skills and advance their careers.",
    "We believe that education should be accessible, engaging, and effective. That's why we combine cutting-edge technology with proven teaching methodologies to create an optimal learning environment.",
    "Our interactive courses, real-world projects, and supportive community make learning both enjoyable and practical.",
  ];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // Delay between children animations
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.6 },
    },
  };

  if (isLoading) {
    return (
      <div className="min-h-[500px] flex items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <div className="relative w-16 h-16">
            <div className="absolute top-0 left-0 w-full h-full border-4 border-gray-200 rounded-full"></div>
            <div className="absolute top-0 left-0 w-full h-full border-4 border-[#4CA6E8] rounded-full animate-spin border-t-transparent"></div>
          </div>
          <p className="text-gray-600 text-lg">Loading content...</p>
        </div>
      </div>
    );
  }

  return (
    <section className="py-8">
      <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-24 2xl:px-40">
        <div className="max-w-7xl mx-auto">
          <motion.div
            className="flex flex-col lg:flex-row items-center justify-center gap-8"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            {/* Image Section */}
            <motion.div className="w-full lg:w-1/2" variants={imageVariants}>
              <motion.div
                className="w-full h-[250px] md:h-[350px] lg:h-[450px] rounded-lg overflow-hidden relative"
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.3 }}
              >
                {!imageLoaded && (
                  <div className="absolute inset-0 bg-gray-100 animate-pulse flex items-center justify-center">
                    <div className="w-10 h-10 border-4 border-[#4CA6E8] border-t-transparent rounded-full animate-spin"></div>
                  </div>
                )}
                <motion.img
                  src={Slide1}
                  alt="Learning That Inspires"
                  className={`w-full h-full object-cover object-center transition-opacity duration-300 ${
                    imageLoaded ? "opacity-100" : "opacity-0"
                  }`}
                  onLoad={handleImageLoad}
                />
              </motion.div>
            </motion.div>

            {/* Content Section */}
            <motion.div
              className="w-full lg:w-1/2"
              variants={containerVariants}
            >
              <motion.h2
                className="text-2xl md:text-3xl font-serif font-bold mb-6 text-blue-900"
                variants={textVariants}
              >
                Learning That Inspires
              </motion.h2>
              <div className="space-y-4">
                {fullText.map((paragraph, index) => (
                  <motion.p
                    key={index}
                    className="text-base md:text-lg text-gray-600 leading-relaxed text-justify"
                    variants={textVariants}
                  >
                    {paragraph}
                  </motion.p>
                ))}
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ABOUTSection;
