import React from "react";
import { motion } from "framer-motion";

interface Instructor {
  id: number;
  name: string;
  role: string;
  experience: string;
  rating: number;
  image: string;
}

const InstructorSection: React.FC = () => {
  const instructors: Instructor[] = [
    {
      id: 1,
      name: "Jennifer Tiffany",
      role: "Programmer",
      experience: "8+ years experience",
      rating: 4.7,
      image:
        "https://img.freepik.com/free-photo/confident-businesswoman-posing-with-arms-crossed_1262-20950.jpg",
    },
    {
      id: 2,
      name: "Wendys Novaria",
      role: "Design UI & UX",
      experience: "6+ years experience",
      rating: 4.7,
      image:
        "https://img.freepik.com/free-photo/young-confident-businessman-suit-standing-with-crossed-arms_171337-18599.jpg",
    },
    {
      id: 3,
      name: "Arthur Giovanni",
      role: "Management Accounting",
      experience: "10+ years experience",
      rating: 4.7,

      image:
        "https://img.freepik.com/free-photo/portrait-successful-man-having-stubble-posing-with-broad-smile-keeping-arms-folded_171337-1267.jpg",
    },
    {
      id: 4,
      name: "Edward Vulin",
      role: "Marketing",
      experience: "7+ years experience",
      rating: 4.7,
      image:
        "https://img.freepik.com/free-photo/handsome-businessman-suit-glasses-cross-arms-chest-look_176420-21750.jpg",
    },
  ];

  // Function to render stars based on rating
  const renderStars = (rating: number) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    return (
      <div className="flex items-center text-yellow-500 text-sm">
        {/* Full stars */}
        {[...Array(fullStars)].map((_, index) => (
          <svg
            key={`full-${index}`}
            className="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.342 4.125a1 1 0 00.95.69h4.337c.969 0 1.371 1.24.588 1.81l-3.5 2.545a1 1 0 00-.364 1.118l1.34 4.127c.3.921-.755 1.688-1.54 1.118l-3.5-2.546a1 1 0 00-1.175 0l-3.5 2.546c-.785.57-1.839-.197-1.54-1.118l1.34-4.127a1 1 0 00-.364-1.118l-3.5-2.545c-.783-.57-.38-1.81.588-1.81h4.337a1 1 0 00.95-.69l1.342-4.125z" />
          </svg>
        ))}

        {/* Half star */}
        {hasHalfStar && (
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <defs>
              <linearGradient id="half-gradient">
                <stop offset="50%" stopColor="currentColor" />
                <stop offset="50%" stopColor="#E5E7EB" />
              </linearGradient>
            </defs>
            <path
              fill="url(#half-gradient)"
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.342 4.125a1 1 0 00.95.69h4.337c.969 0 1.371 1.24.588 1.81l-3.5 2.545a1 1 0 00-.364 1.118l1.34 4.127c.3.921-.755 1.688-1.54 1.118l-3.5-2.546a1 1 0 00-1.175 0l-3.5 2.546c-.785.57-1.839-.197-1.54-1.118l1.34-4.127a1 1 0 00-.364-1.118l-3.5-2.545c-.783-.57-.38-1.81.588-1.81h4.337a1 1 0 00.95-.69l1.342-4.125z"
            />
          </svg>
        )}

        {/* Empty stars */}
        {[...Array(totalStars - Math.ceil(rating))].map((_, index) => (
          <svg
            key={`empty-${index}`}
            className="w-4 h-4"
            fill="#E5E7EB"
            viewBox="0 0 20 20"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.342 4.125a1 1 0 00.95.69h4.337c.969 0 1.371 1.24.588 1.81l-3.5 2.545a1 1 0 00-.364 1.118l1.34 4.127c.3.921-.755 1.688-1.54 1.118l-3.5-2.546a1 1 0 00-1.175 0l-3.5 2.546c-.785.57-1.839-.197-1.54-1.118l1.34-4.127a1 1 0 00-.364-1.118l-3.5-2.545c-.783-.57-.38-1.81.588-1.81h4.337a1 1 0 00.95-.69l1.342-4.125z" />
          </svg>
        ))}

        <span className="ml-1">{rating}</span>
      </div>
    );
  };

  // Animation variants
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  const headerVariants = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.section
      className="py-8 sm:py-12 lg:py-16 bg-gray-50"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
      variants={containerVariants}
    >
      <div className="container mx-auto px-4 md:px-10 lg:px-14 xl:px-28 2xl:px-44">
        <motion.div className="max-w-7xl mx-auto">
          {/* Section Header */}
          <motion.div
            className="flex flex-col sm:flex-row justify-between items-center mb-8 sm:mb-10"
            variants={headerVariants}
          >
            <motion.h2
              className="text-2xl sm:text-3xl lg:text-4xl font-bold text-blue-900 mb-4 sm:mb-0"
              variants={headerVariants}
            >
              Most Popular Instructor
            </motion.h2>
          </motion.div>

          {/* Instructor Cards Grid */}
          <motion.div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
            {instructors.map((instructor) => (
              <motion.div
                key={instructor.id}
                className="bg-[#4CA6E8] rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl text-white p-2"
                variants={cardVariants}
                whileHover={{
                  scale: 1.03,
                  transition: { duration: 0.3, ease: "easeOut" },
                }}
              >
                <motion.div
                  className="h-64 sm:h-48 md:h-52 lg:h-52 overflow-hidden rounded-lg"
                  whileHover={{
                    scale: 1.05,
                    transition: { duration: 0.3, ease: "easeOut" },
                  }}
                >
                  <motion.img
                    src={instructor.image}
                    alt={instructor.name}
                    className="w-full h-full object-cover object-top"
                    variants={{
                      hidden: { scale: 1.2 },
                      visible: {
                        scale: 1,
                        transition: { duration: 0.6, ease: "easeOut" },
                      },
                    }}
                  />
                </motion.div>
                <motion.div className="p-3 sm:p-4" variants={cardVariants}>
                  <h3 className="text-base sm:text-lg font-semibold mb-1">
                    {instructor.name}
                  </h3>
                  <p className="text-sm sm:text-base font-medium mb-1">
                    {instructor.role}
                  </p>
                  <p className="text-xs sm:text-sm opacity-90 mb-2">
                    {instructor.experience}
                  </p>
                  <div>{renderStars(instructor.rating)}</div>
                </motion.div>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default InstructorSection;
