import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  const footerLinks = {
    course: {
      title: "Popular Courses",
      links: [
        { name: "Public Speaking", path: "/courses/web-development" },
        { name: "Practical Sales Skills", path: "/courses/data-science" },
        {
          name: "Emotional Intelligence Training",
          path: "/courses/digital-marketing",
        },
        { name: "Time Management", path: "/courses/ui-ux-design" },
        {
          name: "Human Resource Management Training",
          path: "/courses/business-analytics",
        },
      ],
    },
    learn: {
      title: "Quick Links",
      links: [
        { name: "Success Stories", path: "/success-stories" },
        { name: "Certification Programs", path: "/certifications" },
        { name: "Career Guidance", path: "/career-guidance" },
        { name: "Student Support", path: "/student-support" },
        { name: "Become an Instructor", path: "/teach-with-us" },
      ],
    },
    about: {
      title: "About Us",
      links: [
        { name: "Who Are We?", path: "/mission" },
        { name: "Our Vision", path: "/how-it-works" },
        { name: "Our Mission", path: "/help" },
        { name: "Our Aim", path: "/contact" },
        { name: "Our Offerings", path: "/privacy" },
        { name: "Contact Us", path: "/terms" },
      ],
    },
    community: {
      title: "Community",
      links: [
        { name: "Student Forum", path: "/forum" },
        { name: "Learning Blog", path: "/blog" },
        { name: "Success Stories", path: "/testimonials" },
        { name: "Events & Webinars", path: "/events" },
      ],
    },
  };

  return (
    <footer className="bg-gradient-to-r from-[#4CA6E8] to-[#f08e2c] text-white py-12 mt-16 sm:mt-20 lg:mt-24">
      <div className="mx-6 md:mx-10 lg:mx-14 xl:mx-28 2xl:mx-44">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {/* Logo and Description Column */}
          <div className="lg:col-span-1">
            <div className="-mt-8 mb-2">
              <Link to="/" className="flex items-center">
                <img
                  src={logo}
                  alt="MountSkills"
                  className="h-16 sm:h-24 md:h-28 lg:h-32 w-auto"
                />
              </Link>
            </div>
            <p className="text-sm leading-relaxed opacity-90">
              Where Skills Shape The Future.
            </p>

            {/* Add Contact Information */}
            <div className="space-y-2 mt-2">
              <p className="text-sm opacity-90">
                <span className="font-semibold">Contact Us:</span>
              </p>
              <p className="text-sm opacity-90">
                <span className="font-semibold">USA:</span>{" "}
                <a
                  href="tel:+14696669332"
                  className="hover:text-orange-600 transition-colors"
                >
                  +1 469 666 9332
                </a>
              </p>
              <p className="text-sm opacity-90">
                <span className="font-semibold">Australia:</span>{" "}
                <a
                  href="tel:+61280155605"
                  className="hover:text-orange-600 transition-colors"
                >
                  +61(0) 2 8015 5605
                </a>
              </p>
              <p className="text-sm opacity-90">
                <span className="font-semibold">Email:</span>{" "}
                <a
                  href="mailto:eventbrite@mountskills.com"
                  className="hover:text-orange-600 transition-colors"
                >
                  eventbrite@mountskills.com
                </a>
              </p>
            </div>
          </div>

          {/* Menu Columns */}
          {Object.values(footerLinks).map((section, index) => (
            <div key={index}>
              <h3 className="text-lg font-semibold mb-4">{section.title}</h3>
              <ul className="space-y-2">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <span className="text-sm opacity-90 inline-block">
                      {link.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Copyright and Social Media */}
        <div className="mt-12 pt-4 border-t border-white/20 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm opacity-90">
            © 2024 MountSkills. All rights reserved. Where Skills Shape The
            Future.
          </p>

          {/* Social Media Icons moved here */}
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#3b5998]  transition-colors"
            >
              <FaFacebook size={20} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#1D9BF0] transition-colors"
            >
              <FaTwitter size={20} />
            </a>
            <a
              href="https://www.instagram.com/mountskills_inc/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:bg-instagram-gradient transition-colors"
            >
              <FaInstagram size={20} />
            </a>
            <a
              href="https://www.linkedin.com/company/mount-skills/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#0A66C2] transition-colors"
            >
              <FaLinkedin size={20} />
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-[#FF0000] transition-colors"
            >
              <FaYoutube size={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
