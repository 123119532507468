import React, { useRef } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import testimonialImage1 from "../assets/Madeline_Martin.png";
import testimonialImage2 from "../assets/Miguel_phelan.png";
import testimonialImage3 from "../assets/Abbi_jones.png";
import testimonialVideo from "../assets/testimonial.mp4";

interface Testimonial {
  image: string;
  name: string;
  location: string;
  text: string;
  video?: string;
}

// Define slide animation for right to left
const slideAnimation = {
  hidden: {
    opacity: 0,
    x: 50, // Start from the right
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    x: -50, // Exit to the left
    transition: {
      duration: 0.8,
      ease: "easeIn",
    },
  },
};

const TestimonialCard = ({ image, name, location, text }: Testimonial) => {
  return (
    <motion.div
      className="relative p-1 rounded-xl bg-gradient-to-r from-[#f08e2c] to-[#4CA6E8]"
      variants={slideAnimation} // Use the defined slide animation
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {/* Inner White Background */}
      <div className="bg-white p-6 rounded-lg">
        <motion.div className="flex flex-col items-center">
          <motion.div
            className="overflow-hidden rounded-full mb-4"
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            <motion.img
              src={image}
              alt={name}
              className="w-20 h-20 object-cover"
              initial={{ scale: 1.2 }}
              whileInView={{ scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            />
          </motion.div>
          <motion.h3 className="text-lg md:text-xl font-semibold text-gray-800">
            {name}
          </motion.h3>
          <motion.p className="text-sm md:text-base text-gray-600 mb-4">
            {location}
          </motion.p>
          <motion.p className="text-gray-700 text-center text-sm md:text-base leading-relaxed">
            {text}
          </motion.p>
        </motion.div>
      </div>
    </motion.div>
  );
};

const TestimonialsSection = () => {
  const testimonials = [
    {
      image: testimonialImage1,
      name: "Madeline Martin",
      location: "United States",
      text: "I recently attended a time management training session, & thank you SO MUCH for making today special. The course exceeded my expectations—I learned so much across a broad spectrum, which I will carry forward. You were incredibly generous with your time, advice, and suggestions, and I deeply thank Mount Skills for this. An exciting journey ahead, and I’m grateful for your help in setting me on the right track.",
      video: testimonialVideo,
    },
    {
      image: testimonialImage2,
      name: "Miguel Phelan",
      location: "United States",
      text: "I was impressed by the Practical Sales Skills course. It was extremely valuable, and the instructor was brilliant. I feel confident now, having gained the skills to not only prepare a solid business presentation but also to deliver it effectively. Thank you, Sharon, for providing such a memorable and informative experience. Your expertise truly made a difference, and I’m excited to apply what I’ve learned moving forward.",
    },
    {
      image: testimonialImage3,
      name: "Abbi Jones",
      location: "United States",
      text: "I recently attended the Leadership Skills, Inspire with Celia Faye Meisel, and it was absolutely outstanding! Celia's expertise and understanding of the subject made the sessions both enlightening and engaging. Drew's proactive communication ensured everything ran smoothly. The Milwaukee venue provided the perfect backdrop for growth. Overall, it was an incredible experience that I highly recommend!",
    },
  ];

  const videoRef = useRef<HTMLVideoElement | null>(null);

  return (
    <motion.div
      className="bg-white"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8 }}
    >
      <motion.section className="py-8">
        <div className="mx-6 md:mx-10 lg:mx-14 xl:mx-28 2xl:mx-44">
          <div className="max-w-7xl mx-auto">
            {/* Video Section */}
            <div className="flex mb-8 flex-col md:flex-row">
              <div className="flex-grow mr-4 flex items-center">
                <motion.h2
                  className="text-3xl md:text-4xl font-serif font-bold bg-gradient-to-r from-[#f48b14] to-[#4CA6E8] bg-clip-text text-transparent mb-2 pt-4 md:pt-6"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6 }}
                >
                  Hear from our participants about how our courses have
                  empowered their learning journey and career growth.
                </motion.h2>
              </div>
              {testimonials[0].video && (
                <div className="flex-shrink-0 w-full md:w-1/2 relative">
                  <motion.video
                    ref={videoRef}
                    src={testimonials[0].video}
                    controls
                    playsInline
                    className="w-full rounded-lg"
                    initial={{ scale: 1.2 }}
                    whileInView={{ scale: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  />
                </div>
              )}
            </div>

            {/* Member Testimonials Header */}
            <div className="flex-grow mr-4 flex items-center justify-center">
              <motion.h2
                className="text-3xl md:text-4xl font-serif font-bold bg-gradient-to-r from-[#4CA6E8] to-[#f08e2c] bg-clip-text text-transparent mb-8 text-center"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
              >
                Feedback from Participants
              </motion.h2>
            </div>

            {/* Testimonials Swiper */}
            <div className="flex items-center">
              <Swiper
                className="mySwiper"
                modules={[Pagination, Autoplay]}
                spaceBetween={20}
                slidesPerView={1}
                pagination={{ clickable: true, el: ".custom-pagination" }}
                autoplay={{ delay: 8000 }}
                loop={true}
              >
                {testimonials.map((testimonial, index) => (
                  <SwiperSlide key={index}>
                    <TestimonialCard
                      image={testimonial.image}
                      name={testimonial.name}
                      location={testimonial.location}
                      text={testimonial.text}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              {/* Custom Pagination Dots */}
              <div className="custom-pagination flex flex-col justify-center ml-4" />
            </div>
          </div>
        </div>
      </motion.section>
    </motion.div>
  );
};

export default TestimonialsSection;
