import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import TimeManagementImg from "../assets/time-management.jpg";
import SalesSkillsImg from "../assets/sales-skills.webp";
import HRManagementImg from "../assets/hr-management.webp";
import EmotionalIntelligenceImg from "../assets/emotional-intelligence.webp";
import PublicSpeakingImg from "../assets/public-speaking.webp";
import ConflictManagementImg from "../assets/conflict-management.webp";

const PopularSection: React.FC = () => {
  const courses = [
    {
      id: 1,
      title: "Public Speaking",
      category: "Softskills",
      description:
        "Build confidence, engage audiences, and elevate your presentation skills to become an unforgettable speaker in any setting.",
      rating: 5,
      image: PublicSpeakingImg,
    },
    {
      id: 2,
      title: "Practical Sales Skills",
      category: "Business Improvement & Marketing",
      description:
        "Develop practical sales techniques that deliver results. Learn how to connect with clients, close deals, and achieve your sales goals with ease.",
      rating: 5,
      image: SalesSkillsImg,
    },
    {
      id: 3,
      title: "Emotional Intelligence",
      category: "Professional Skills",
      description:
        "Gain strategies to manage emotions effectively, enhance relationships, and foster empathy for growth in both personal and professional spaces.",
      rating: 4.9,
      image: EmotionalIntelligenceImg,
    },
    {
      id: 4,
      title: "Time Management",
      category: "Management Skills",
      description:
        "Master time management techniques to prioritize tasks, stay focused, and accomplish more with reduced stress.",
      rating: 4.8,
      image: TimeManagementImg,
    },
    {
      id: 5,
      title: "Human Resource Management",
      category: "Management Skills",
      description:
        "Gain essential HR skills to lead teams, support employee growth, and drive organizational success in today's dynamic workplace.",
      rating: 4.8,
      image: HRManagementImg,
    },
    {
      id: 6,
      title: "Leadership Skills",
      category: "Soft Skills",
      description:
        "Master leadership skills to inspire teams, boost motivation, and create a positive workplace culture.",
      rating: 4.5,
      image: ConflictManagementImg,
    },
  ];

  return (
    <section className="py-8 bg-white">
      <div className="container mx-auto px-4 md:px-8 lg:px-12 xl:px-24 2xl:px-40">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-blue-900 mb-8">
          Popular Courses
        </h2>

        <Swiper
          className="custom-swiper"
          modules={[Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2000 }}
          loop={true}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
        >
          {courses.map((course) => (
            <SwiperSlide key={course.id}>
              <div className="bg-blue-900 rounded-lg shadow-md overflow-hidden p-2 sm:p-6 mx-auto max-w-xs flex flex-col ">
                <img
                  src={course.image}
                  alt={course.title}
                  className="h-48 w-full object-cover mb-4"
                />
                <div className="flex flex-col flex-grow mb-2">
                  <div className="flex flex-col flex-grow line-clamp-5">
                    <h3 className="text-lg font-semibold text-white mb-1 text-center">
                      {course.title}
                    </h3>
                    <div className="bg-blue-500 text-white text-xs font-semibold rounded-full px-2 py-1 mb-2 w-full text-center">
                      {course.category}
                    </div>
                    <p className="text-white text-sm">{course.description}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <style>{`
        .custom-swiper .swiper-pagination {
          bottom: -30px;
        }
        .line-clamp-5 {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
      `}</style>
    </section>
  );
};

export default PopularSection;
