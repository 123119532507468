import React from "react";

const Tagline: React.FC = () => {
  return (
    <nav className="bg-gradient-to-r from-[#f08e2c] to-[#4CA6E8] h-8">
      <div className="px-4 sm:px-10">
        <div className="h-full flex items-center justify-center">
          <div className="flex flex-col items-center mb-6 sm:mb-0 w-full">
            <div className="overflow-hidden whitespace-nowrap w-full">
              <div className="marquee">
                <span className="text-white font-bold text-lg  py-2">
                  Where Skills Shape The Future | Need help? Call us at | USA:
                  +1 469 666 9332 | Australia: +61 (0) 2 8015 5605 | Email:
                  eventbrite@mountskills.com
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>{`
        .marquee {
          display: inline-block;
          animation: marquee 20s linear infinite;
          white-space: nowrap; /* Ensure text doesn't wrap */
          width: 100%; /* Full width */
          text-align: center; /* Center the text */
        }

        @keyframes marquee {
          0% {
            transform: translateX(100%); /* Start from the right end */
          }
          100% {
            transform: translateX(-100%); /* Move to the left end */
          }
        }
      `}</style>
    </nav>
  );
};

export default Tagline;
