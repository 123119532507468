import React from "react";

import FooterSection from "../components/footer-section";
import CoursesSection from "../components/courses-section";
import Navbar from "../components/navbar-section";

const CoursesPage: React.FC = () => {
  return (
    <div>
      <Navbar />
      <CoursesSection />
      <FooterSection />
    </div>
  );
};
export default CoursesPage;
