import React, { useState, useEffect } from "react";
import FooterSection from "../components/footer-section";
import AboutUs from "../components/about-us";
import ContactSection from "../components/contact-section";
import { motion, AnimatePresence } from "framer-motion";
import Navbar from "../components/navbar-section";

const AboutUsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500); // Adjust timing as needed

    return () => clearTimeout(timer);
  }, []);

  // Loader component
  const Loader = () => (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <div className="relative">
          {/* Outer circle */}
          <div className="w-16 h-16 border-4 border-gray-200 rounded-full"></div>
          {/* Spinning inner circle */}
          <div className="w-16 h-16 border-4 border-[#4CA6E8] border-t-transparent rounded-full animate-spin absolute top-0 left-0"></div>
        </div>
        <div className="text-gray-600 text-lg font-medium">
          Loading About Us...
        </div>
        {/* Optional loading progress bar */}
        <div className="w-48 h-1 bg-gray-200 rounded-full overflow-hidden">
          <div className="h-full bg-[#4CA6E8] animate-loadingProgress"></div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Loader />
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoading ? 0 : 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className={isLoading ? "hidden" : ""}>
          <Navbar />
          <AboutUs />
          <motion.div
            initial={{ opacity: 0, y: 100, visibility: "hidden" }}
            whileInView={{ opacity: 1, y: 0, visibility: "visible" }}
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="overflow-hidden"
          >
            {" "}
            <ContactSection />
          </motion.div>
          <FooterSection />
        </div>
      </motion.div>
    </>
  );
};

export default AboutUsPage;
