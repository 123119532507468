import { motion } from "framer-motion";
import internalImage from "../assets/about_us.jpeg";

const AboutUs = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const styles = `
    .shape-bg {
      position: relative;
      overflow: hidden;
    }

    .shape-bg::before {
      content: '';
      position: absolute;
      top: -50%;
      right: -50%;
      width: 100%;
      height: 100%;
      background: #f48b14;
      opacity: 0.03;
      border-radius: 50%;
      transform: scale(1);
      transition: transform 0.3s ease;
    }

    .shape-bg:hover::before {
      transform: scale(1.1);
    }

    .shape-dots {
      background-image: radial-gradient(#f48b14 1px, transparent 1px);
      background-size: 20px 20px;
      opacity: 0.1;
    }

    .shape-lines {
      background: repeating-linear-gradient(
        45deg,
        #f48b14 0,
        #f48b14 1px,
        transparent 1px,
        transparent 10px
      );
      opacity: 0.05;
    }

    .curved-shape {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 300px;
      background: #60B3F0;
      opacity: 0.03;
      clip-path: path('M 0 0 C 100 50, 100 150, 0 200 L 300 200 L 300 0 Z');
    }

    .curved-shape-left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 300px;
      background: #60B3F0;
      opacity: 0.03;
      clip-path: path('M 300 0 C 200 50, 200 150, 300 200 L 0 200 L 0 0 Z');
    }
  `;

  const whoWeAreSection = (
    <motion.div variants={itemVariants} className="text-center mb-16 relative">
      <div className="absolute inset-0 shape-dots" />
      <div className="relative z-10">
        <h3 className="text-2xl font-bold text-gray-800 mb-6">Who Are We?</h3>
        <div className="mb-8">
          <h4 className="text-xl font-bold bg-clip-text text-transparent bg-primary-gradient mb-4">
            MOUNTSKILLS
          </h4>
          <p className="text-gray-600 max-w-3xl mx-auto mb-12">
            At MountSkills, our mission is to empower individuals and
            organizations through a comprehensive learning approach that builds
            expertise and confidence. We focus on bridging the gap between
            knowledge and skills to help professionals thrive in an evolving
            work environment.
          </p>

          <motion.div
            className="max-w-4xl mx-auto relative"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="relative h-[500px] w-full rounded-2xl overflow-hidden shadow-lg group">
              <img
                src={internalImage}
                alt="MountSkills Learning Environment"
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-700"
              />
              {/* Overlay gradient */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <section className="w-full bg-white pt-1 pb-16">
      <style>{styles}</style>
      {/* Blue Background Header Section */}
      <div className="bg-3D-gradient py-12 mb-12">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <motion.h2
              className="text-4xl font-bold text-white"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8 }}
            >
              Know About Us
            </motion.h2>
          </div>
        </div>
      </div>

      {/* Content Container */}
      <div className="container mx-auto px-4">
        <motion.div
          className="max-w-6xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {/* Who Are We - with circular background */}
          {whoWeAreSection}

          {/* Grid Sections */}
          <div className="grid md:grid-cols-2 gap-8">
            {/* Our Vision */}
            <motion.div
              variants={itemVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <div className="shape-bg p-8 h-full bg-[#f5e6d3] hover:bg-[#f5e6d3]/90 transition-all duration-300 relative overflow-hidden rounded-2xl border border-gray-200/50 shadow-sm">
                <div className="relative z-10">
                  <h3 className="text-xl font-bold text-blue-900 mb-4">
                    Our Vision
                  </h3>
                  <p className="text-gray-600">
                    To empower learners globally by fostering skill development
                    and knowledge while developing adaptable capabilities. Our
                    aim is to help students achieve their full potential in a
                    fast-paced and changing world.
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Our Mission */}
            <motion.div
              variants={itemVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <div className="shape-bg p-8 h-full bg-[#f5e6d3] hover:bg-[#f5e6d3]/90 transition-all duration-300 relative overflow-hidden rounded-2xl border border-gray-200/50 shadow-sm">
                <div className="relative z-10">
                  <h3 className="text-xl font-bold text-blue-900 mb-4">
                    Our Mission
                  </h3>
                  <p className="text-gray-600">
                    To make high-quality, accessible education a reality for
                    all, enabling individuals to gain crucial skills and advance
                    their careers. We are committed to creating a supportive
                    learning environment that blends innovative approaches and
                    comprehensive education solutions.
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Our Aim */}
            <motion.div
              variants={itemVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <div className="shape-bg p-8 h-full bg-[#f5e6d3] hover:bg-[#f5e6d3]/90 transition-all duration-300 relative overflow-hidden rounded-2xl border border-gray-200/50 shadow-sm">
                <div className="relative z-10">
                  <h3 className="text-xl font-bold text-blue-900 mb-4">
                    Our Aim
                  </h3>
                  <p className="text-gray-600">
                    To revolutionize professional development through
                    accessible, effective, and flexible learning programs that
                    equip individuals and teams for success. We provide pathways
                    to help professionals build confidence and navigate the
                    challenges of career growth.
                  </p>
                </div>
              </div>
            </motion.div>

            {/* Our Offerings */}
            <motion.div
              variants={itemVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <div className="shape-bg p-8 h-full bg-[#f5e6d3] hover:bg-[#f5e6d3]/90 transition-all duration-300 relative overflow-hidden rounded-2xl border border-gray-200/50 shadow-sm">
                <div className="relative z-10">
                  <h3 className="text-xl font-bold text-blue-900 mb-4">
                    Our Offerings
                  </h3>
                  <p className="text-gray-600">
                    Our program offerings are tailored to cater to the different
                    learning preferences of our participants. From practical
                    interactive training to expert-led webinars, we blend modern
                    learning experiences that align with industry standards.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>

          {/* Why Choose Mountskills */}
          <motion.div
            variants={itemVariants}
            initial="hidden"
            whileInView="visible"
            className="text-center max-w-3xl mx-auto mt-16"
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="shape-bg p-8 bg-[#f5e6d3] hover:bg-[#f5e6d3]/90 transition-all duration-300 relative overflow-hidden rounded-2xl border border-gray-200/50 shadow-sm">
              <div className="relative z-10">
                <h3 className="text-xl font-bold text-blue-900 mb-4">
                  Why Choose Mountskills?
                </h3>
                <p className="text-gray-600">
                  Join MountSkills to take your career to the next level with
                  our expertly crafted training solutions. We aim to provide a
                  learning experience that empowers you to achieve professional
                  excellence confidently and efficiently.
                </p>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;
