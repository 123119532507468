import React from "react";
import FooterSection from "../components/footer-section";
import ContactSection from "../components/contact-section";
import Navbar from "../components/navbar-section";

const ContactPage: React.FC = () => {
  return (
    <div>
      <Navbar />
      <ContactSection />
      <FooterSection />
    </div>
  );
};
export default ContactPage;
