import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiX, HiMenu } from "react-icons/hi";
import logo from "../assets/logo.png";

interface MenuItem {
  href: string;
  label: string;
  target?: string;
  rel?: string;
  isExternal?: boolean;
  submenu?: MenuItem[];
  subcourses?: MenuItem[];
}

const menuItems: MenuItem[] = [
  { href: "/", label: "Home", isExternal: false },
  {
    href: "/courses",
    label: "Courses",
    isExternal: false,
    submenu: [
      //Soft Skills
      {
        href: "/courses/soft-skills",
        label: "Soft Skills",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1082931751019?aff=oddtdtcreator",
            label: "Anger Management",
          },
          {
            href: "https://www.eventbrite.com/e/1082955923319?aff=oddtdtcreator",
            label: "Communication Skills",
          },
          {
            href: "https://www.eventbrite.com/e/1082947999619?aff=oddtdtcreator",
            label: "Communication Strategies",
          },
          {
            href: "https://www.eventbrite.com/e/1083929575539?aff=oddtdtcreator",
            label: "Internal Customer Service",
          },
          {
            href: "https://www.eventbrite.com/e/1082896164579?aff=oddtdtcreator",
            label: "Leadership Skills - Lead, Motivate & Inspire",
          },
          {
            href: "https://www.eventbrite.com/e/1083928753079?aff=oddtdtcreator",
            label: "Managing People",
          },
          {
            href: "https://www.eventbrite.com/e/1083929374939?aff=oddtdtcreator",
            label: "Negotiation Skills - Expert",
          },
          {
            href: "https://www.eventbrite.com/e/1083834270479?aff=oddtdtcreator",
            label: "Negotiation Skills - Professional",
          },
          {
            href: "https://www.eventbrite.com/e/1086676772479?aff=oddtdtcreator",
            label: "Personal Branding",
          },
          {
            href: "https://www.eventbrite.com/e/1083807169419?aff=oddtdtcreator",
            label: "Presentation Skills",
          },
          {
            href: "https://www.eventbrite.com/e/1086708527459?aff=oddtdtcreator",
            label: "Presentation Skills - Expert",
          },
          {
            href: "https://www.eventbrite.com/e/1083807159389?aff=oddtdtcreator",
            label: "Presentation Skills - Professional",
          },
          {
            href: "https://www.eventbrite.com/e/1082887378299?aff=oddtdtcreator",
            label: "Public Speaking",
          },
          {
            href: "https://www.eventbrite.com/e//1086677073379?aff=oddtdtcreator",
            label: "Stress Management",
          },
          {
            href: "https://www.eventbrite.com/e/1082973345429?aff=oddtdtcreator",
            label: "Workplace Diversity & Inclusion",
          },
          {
            href: "https://www.eventbrite.com/e/1083929535419?aff=oddtdtcreator",
            label: "Preparing for Job Interviews",
          },
        ],
      },
      // Management Skills
      {
        href: "/courses/management-skills",
        label: "Management Skills",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1086676100469?aff=oddtdtcreator",
            label: "Account Management",
          },
          {
            href: "https://www.eventbrite.com/e/1086676120529?aff=oddtdtcreator",
            label: "Building High Performing Teams",
          },
          {
            href: "https://www.eventbrite.com/e/1082918170399?aff=oddtdtcreator",
            label: "Conflict Management",
          },
          {
            href: "https://www.eventbrite.com/e/1086676150619?aff=oddtdtcreator",
            label: "Crisis Management",
          },
          {
            href: "https://www.eventbrite.com/e/1086676170679?aff=oddtdtcreator",
            label: "Effective Vendor Management",
          },
          {
            href: "https://www.eventbrite.com/e/1083834240389?aff=oddtdtcreator",
            label: "Employee Engagement",
          },
          {
            href: "https://www.eventbrite.com/e/1082925121189?aff=oddtdtcreator",
            label: "Essential Management Skills",
          },
          {
            href: "https://www.eventbrite.com/e/1082901741259?aff=oddtdtcreator",
            label: "Human Resource Management",
          },
          {
            href: "https://www.eventbrite.com/e/1082935010769?aff=oddtdtcreator",
            label: "Leading Effective Teams",
          },
          {
            href: "https://www.eventbrite.com/e/1083928492299?aff=oddtdtcreator",
            label: "Motivating People",
          },
          {
            href: "https://www.eventbrite.com/e/1083807129299?aff=oddtdtcreator",
            label: "Performance Management",
          },
          {
            href: "https://www.eventbrite.com/e/1083928702929?aff=oddtdtcreator",
            label: "Supply Chain Management",
          },
          {
            href: "https://www.eventbrite.com/e/1082923125219?aff=oddtdtcreator",
            label: "Time Management",
          },
          {
            href: "https://www.eventbrite.com/e/1083835173179?aff=oddtdtcreator",
            label: "Managing Performance",
          },
        ],
      },
      // Professional Skills
      {
        href: "/courses/professional-skills",
        label: "Professional Skills",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1083775404409?aff=oddtdtcreator",
            label: "10 Soft Skills You Need",
          },
          {
            href: "https://www.eventbrite.com/e/1083799957849?aff=oddtdtcreator",
            label: "Customer Service Essentials",
          },
          {
            href: "https://www.eventbrite.com/e/1082891921889?aff=oddtdtcreator",
            label: "Emotional Intelligence",
          },
          {
            href: "https://www.eventbrite.com/e/1083834862249?aff=oddtdtcreator",
            label: "Essential Coaching Skills for Managers",
          },
          {
            href: "https://www.eventbrite.com/e/1082974137799?aff=oddtdtcreator",
            label: "Event Planning",
          },
          {
            href: "https://www.eventbrite.com/e/1086676030259?aff=oddtdtcreator",
            label: "Interview Skills",
          },
          {
            href: "https://www.eventbrite.com/e/1083770178779?aff=oddtdtcreator",
            label: "Organizational Skills",
          },
          {
            href: "https://www.eventbrite.com/e/1086728838209?aff=oddtdtcreator",
            label: "Professional Development Essentials",
          },
          {
            href: "https://www.eventbrite.com/e/1083928432119?aff=oddtdtcreator",
            label: "Recruitment Skills",
          },
          {
            href: "https://www.eventbrite.com/e/1086697113319?aff=oddtdtcreator",
            label: "Stakeholder Management",
          },
          {
            href: "https://www.eventbrite.com/e/1083758584099?aff=oddtdtcreator",
            label: "Train-The-Trainer",
          },
          {
            href: "https://www.eventbrite.com/e/1083835203269?aff=oddtdtcreator",
            label: "Proposal Writing",
          },
        ],
      },
      //Business Improvement & Marketing
      {
        href: "/courses/business-improvement",
        label: "Business Improvement & Marketing",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1084000808599?aff=oddtdtcreator",
            label: "Business Case Writing",
          },
          {
            href: "https://www.eventbrite.com/e/1084000878809?aff=oddtdtcreator",
            label: "Business Ethics",
          },
          {
            href: "https://www.eventbrite.com/e/1083929114159?aff=oddtdtcreator",
            label: "Business Etiquette",
          },
          {
            href: "https://www.eventbrite.com/e/1082929594569?aff=oddtdtcreator",
            label: "Business Finance Basics",
          },
          {
            href: "https://www.eventbrite.com/e/1086708507399?aff=oddtdtcreator",
            label: "Business Process Analysis & Design",
          },
          {
            href: "https://www.eventbrite.com/e/1083807139329?aff=oddtdtcreator",
            label: "Business Writing",
          },
          {
            href: "https://www.eventbrite.com/e/1083834792039?aff=oddtdtcreator",
            label: "Internet Marketing Fundamentals",
          },
          {
            href: "https://www.eventbrite.com/e/1084000908899?aff=oddtdtcreator",
            label: "Internet of Things - Business Perspectives",
          },
          {
            href: "https://www.eventbrite.com/e/1084000938989?aff=oddtdtcreator",
            label: "Practical Sales Skills",
          },
          {
            href: "https://www.eventbrite.com/e/1084000979109?aff=oddtdtcreator",
            label: "Sales Management",
          },
          {
            href: "https://www.eventbrite.com/e/1083799536589?aff=oddtdtcreator",
            label: "Sales Presentation Mastery",
          },
          {
            href: "https://www.eventbrite.com/e/1083834410899?aff=oddtdtcreator",
            label: "Professional Selling Skills",
          },
        ],
      },
      //Agile Management
      {
        href: "/courses/agile-management",
        label: "Agile Management",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1083928602629?aff=oddtdtcreator",
            label: "Agile & Scrum",
          },
          {
            href: "https://www.eventbrite.com/e/1084000066379?aff=oddtdtcreator",
            label: "Agile for Product Owner",
          },
          {
            href: "https://www.eventbrite.com/e/1083929946649?aff=oddtdtcreator",
            label: "Agile Scrum Master",
          },
          {
            href: "https://www.eventbrite.com/e/1086708326859?aff=oddtdtcreator",
            label: "AgileScrum Foundation",
          },
        ],
      },

      //Construction Quality Management
      {
        href: "/courses/construction-quality-management",
        label: "Construction Quality Management",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1082984849839?aff=oddtdtcreator",
            label: "Construction Project Manager",
          },
          {
            href: "https://www.eventbrite.com/e/1083838071849?aff=oddtdtcreator",
            label: "Quality Management",
          },
        ],
      },

      // Data Management & Power BI
      {
        href: "/courses/data-management",
        label: "Data Management & Power BI",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1083834340689?aff=oddtdtcreator",
            label: "Microsoft Power BI",
          },
          {
            href: "https://www.eventbrite.com/e/1086728828179?aff=oddtdtcreator",
            label: "Power BI Dashboard and Data Analysis",
          },
          {
            href: "https://www.eventbrite.com/e/1086697303889?aff=oddtdtcreator",
            label: "Microsoft Power BI for Business Users",
          },
        ],
      },
      // IT Security
      {
        href: "/courses/it-security",
        label: "IT Security",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1083929174339?aff=oddtdtcreator",
            label: "Cyber Security",
          },
          {
            href: "https://www.eventbrite.com/e/1083929555479?aff=oddtdtcreator",
            label: "Cyber Security Overview",
          },
        ],
      },

      //Project Management
      {
        href: "/courses/project-management",
        label: "Project Management",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1083807149359?aff=oddtdtcreator",
            label: "Effective Change Management",
          },
          {
            href: "https://www.eventbrite.com/e/1086728818149?aff=oddtdtcreator",
            label: "Microsoft Project",
          },
          {
            href: "https://www.eventbrite.com/e/1083929224489?aff=oddtdtcreator",
            label: "Project Management Basics",
          },
        ],
      },

      //Software and Technology
      {
        href: "/courses/software-technology",
        label: "Software and Technology",
        isExternal: false,
        subcourses: [
          {
            href: "https://www.eventbrite.com/e/1086697043109?aff=oddtdtcreator",
            label: "AI Powered Marketing",
          },
          {
            href: "/courses/software/web-of-things", // link Not Available
            label: "Artificial Intelligence and Machine Learning",
          },
        ],
      },
    ],
  },
  { href: "/contact", label: "Contact", isExternal: false },
  { href: "/about", label: "About", isExternal: false },
];

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const [openSubcourses, setOpenSubcourses] = useState<string | null>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderMenuItem = (item: MenuItem) => {
    if (item.submenu) {
      return (
        <div className="relative group flex items-center">
          <div className="flex items-center">
            <Link
              to={item.href}
              className="px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900"
            >
              {item.label}
            </Link>
            <svg
              className="w-4 h-4 cursor-pointer transition-transform duration-200 group-hover:rotate-180"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <div className="absolute left-0 hidden group-hover:block w-72 pt-2 z-50 top-full">
            <div className="bg-white border rounded-lg shadow-lg">
              {item.submenu.map((category) => (
                <div key={category.href} className="group/category relative">
                  {category.subcourses ? (
                    <button
                      onClick={() =>
                        setOpenSubmenu(
                          openSubmenu === category.href ? null : category.href
                        )
                      }
                      className="inline-flex w-full px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-100 whitespace-normal justify-between items-center"
                    >
                      {category.label}
                      <svg
                        className={`w-4 h-4 transform transition-transform duration-200 ${
                          openSubmenu === category.href ? "rotate-90" : ""
                        }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </button>
                  ) : (
                    <Link
                      to={category.href}
                      className="inline-flex w-full px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-100 whitespace-normal"
                    >
                      {category.label}
                    </Link>
                  )}
                  {category.subcourses && (
                    <div
                      className={`absolute right-full top-0 w-72 pr-2 ${
                        openSubmenu === category.href ? "block" : "hidden"
                      }`}
                    >
                      <div className="bg-white border rounded-lg shadow-lg max-h-[calc(100vh-120px)] overflow-y-auto custom-scrollbar">
                        {category.subcourses.map((course) => (
                          <Link
                            key={course.href}
                            to={course.href}
                            className="inline-flex w-full px-4 py-2.5 text-sm text-gray-700 hover:bg-gray-100 whitespace-normal"
                            onClick={() => {
                              setOpenSubmenu(null);
                              setIsMenuOpen(false);
                            }}
                          >
                            {course.label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    if (item.isExternal) {
      return (
        <a
          key={item.href}
          href={item.href}
          target={item.target}
          rel={item.rel}
          className="px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900"
        >
          {item.label}
        </a>
      );
    }

    return (
      <Link
        key={item.href}
        to={item.href}
        className="inline-flex px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900"
      >
        {item.label}
      </Link>
    );
  };

  const renderMobileMenuItem = (item: MenuItem) => {
    if (item.submenu) {
      const isOpen = openSubmenu === item.href;

      return (
        <div key={item.href}>
          <button
            onClick={() => setOpenSubmenu(isOpen ? null : item.href)}
            className="w-full flex items-center justify-between px-3 py-2 text-base font-medium text-gray-700"
          >
            <span>{item.label}</span>
            <svg
              className={`w-4 h-4 transition-transform duration-200 ${
                isOpen ? "rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>

          <div
            className={`overflow-hidden transition-all duration-200 ${
              isOpen ? "max-h-[1000px]" : "max-h-0"
            }`}
          >
            {item.submenu.map((category) => (
              <div key={category.href} className="pl-4">
                {category.subcourses ? (
                  <button
                    onClick={() =>
                      setOpenSubcourses(
                        openSubcourses === category.href ? null : category.href
                      )
                    }
                    className="w-full flex items-center justify-between px-3 py-2 text-sm font-medium text-gray-600"
                  >
                    <span>{category.label}</span>
                    <svg
                      className={`w-4 h-4 transition-transform duration-200 ${
                        openSubcourses === category.href ? "rotate-180" : ""
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                ) : (
                  <Link
                    to={category.href}
                    className="block px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                    onClick={() => {
                      setIsMenuOpen(false);
                      setOpenSubmenu(null);
                    }}
                  >
                    {category.label}
                  </Link>
                )}

                {category.subcourses && (
                  <div
                    className={`pl-4 overflow-hidden transition-all duration-200 ${
                      openSubcourses === category.href
                        ? "max-h-[2000px]"
                        : "max-h-0"
                    }`}
                  >
                    {category.subcourses.map((course) => (
                      <Link
                        key={course.href}
                        to={course.href}
                        className="block px-3 py-2 text-sm font-medium text-gray-500 hover:text-gray-900"
                        onClick={() => {
                          setIsMenuOpen(false);
                          setOpenSubmenu(null);
                          setOpenSubcourses(null);
                        }}
                      >
                        {course.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    }

    return item.isExternal ? (
      <a
        key={item.href}
        href={item.href}
        target={item.target}
        rel={item.rel}
        className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900"
      >
        {item.label}
      </a>
    ) : (
      <Link
        key={item.href}
        to={item.href}
        className="block px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900"
        onClick={() => setIsMenuOpen(false)}
      >
        {item.label}
      </Link>
    );
  };

  const renderSidebarMenu = () => {
    return (
      <div className="fixed left-0 top-0 w-64 h-full bg-white shadow-lg">
        <div className="p-4">
          {menuItems[1].submenu?.map((category) => (
            <div key={category.href} className="mb-2">
              <Link
                to={category.href}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                {category.label}
              </Link>
              {category.subcourses && (
                <div className="pl-4">
                  {category.subcourses.map((course) => (
                    <Link
                      key={course.href}
                      to={course.href}
                      className="block px-4 py-2 text-gray-600 hover:bg-gray-100"
                    >
                      {course.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="px-10">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/">
              <img className="h-24 w-auto" src={logo} alt="MountSkills" />
            </Link>
          </div>

          <div className="hidden sm:flex sm:items-center">
            {menuItems.map((item) => renderMenuItem(item))}
          </div>

          <div className="sm:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span className="sr-only">Open sidebar</span>
              {isMenuOpen ? (
                <HiX className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <HiMenu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <>
          {renderSidebarMenu()}
          <div className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {menuItems.map((item) => renderMobileMenuItem(item))}
            </div>
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;
