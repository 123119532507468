import React, { useState, useMemo, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { Course, coursesData } from "../data/courses-data";
import { motion } from "framer-motion";

const ITEMS_PER_LOAD = 10;

const CoursesSection: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [visibleItems, setVisibleItems] = useState(ITEMS_PER_LOAD);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [navigatingCourses, setNavigatingCourses] = useState<{
    [key: string]: boolean;
  }>({});

  const dropdownRef = useRef<HTMLDivElement>(null);

  const categories = useMemo(() => {
    const uniqueCategories = new Set(
      coursesData.map((course) => course.category)
    );
    return ["All Categories", ...Array.from(uniqueCategories)];
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredCourses = useMemo(() => {
    return coursesData.filter((course: Course) => {
      const searchTermNormalized = searchTerm.toLowerCase().replace(/\s+/g, "");
      const titleNormalized = course.title.toLowerCase().replace(/\s+/g, "");
      const descriptionNormalized = course.description
        .toLowerCase()
        .replace(/\s+/g, "");
      const categoryNormalized = course.category
        .toLowerCase()
        .replace(/\s+/g, "");

      const matchesSearch =
        titleNormalized.includes(searchTermNormalized) ||
        descriptionNormalized.includes(searchTermNormalized) ||
        categoryNormalized.includes(searchTermNormalized);

      const matchesCategory =
        selectedCategory === "" ||
        selectedCategory === "All Categories" ||
        course.category === selectedCategory;

      return matchesSearch && matchesCategory;
    });
  }, [searchTerm, selectedCategory]);

  const displayedCourses = filteredCourses.slice(0, visibleItems);
  const hasMore = visibleItems < filteredCourses.length;

  const handleLoadMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleItems((prev) => prev + ITEMS_PER_LOAD);
      setIsLoading(false);
    }, 500);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setVisibleItems(ITEMS_PER_LOAD);
  };

  const handleLearnMore = (link: string, courseId: string) => {
    setNavigatingCourses((prev) => ({ ...prev, [courseId]: true }));
    setTimeout(() => {
      window.location.href = link;
    }, 500);
  };

  const courseVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.1,
      },
    }),
  };

  return (
    <section className="pt-20 bg-3D-gradient">
      <div className="container mx-auto px-4 pb-20">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="font-serif text-4xl font-bold text-gray-50 mb-4">
            Our Featured Courses
          </h2>
          <p className="text-gray-100 max-w-2xl mx-auto mb-8">
            Explore our most popular courses designed to help you develop
            essential skills for professional success.
          </p>

          {/* Search and Filter Section */}
          <div className="max-w-4xl mx-auto flex flex-col md:flex-row gap-4 mb-8">
            {/* Search Bar */}
            <div className="flex-1 relative">
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Search courses..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f48b14] focus:border-transparent"
              />
            </div>

            {/* Category Dropdown */}
            <div ref={dropdownRef} className="md:w-64 relative">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#f48b14] focus:border-transparent bg-white text-left flex justify-between items-center overflow-hidden"
              >
                <span className="truncate">
                  {selectedCategory || "All Categories"}
                </span>
                <svg
                  className={`fill-current h-4 w-4 transition-transform duration-200 ${
                    isDropdownOpen ? "rotate-180" : ""
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </button>

              {isDropdownOpen && (
                <div className="absolute z-50 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto">
                  {categories.map((category) => (
                    <button
                      key={category}
                      onClick={() => {
                        setSelectedCategory(category);
                        setIsDropdownOpen(false);
                      }}
                      className={`w-full px-4 py-2 text-left hover:bg-gray-100 ${
                        selectedCategory === category ? "bg-gray-50" : ""
                      }`}
                    >
                      {category}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Course Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {displayedCourses.length > 0 ? (
            displayedCourses.map((course: Course, index: number) => (
              <motion.div
                key={course.id}
                variants={courseVariants}
                initial="hidden"
                animate="visible"
                custom={index}
              >
                <div className="bg-3D-gradient rounded-[2rem] overflow-hidden shadow-xl hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105 p-6">
                  <div className="text-sm text-gray-100 mb-2 font-sans">
                    {course.category}
                  </div>
                  <h3 className="font-serif text-xl font-bold text-gray-100 mb-2">
                    {course.title}
                  </h3>
                  <p className="text-gray-100 mb-4">{course.description}</p>
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-sm text-gray-100">
                      {course.duration}
                    </span>
                    <span className="text-sm text-gray-100">
                      {course.level}
                    </span>
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={() =>
                        handleLearnMore(course.link, course.id.toString())
                      }
                      className="w-full flex justify-center items-center bg-transparent border-2 border-[#f48b14] rounded-full relative overflow-hidden group py-3"
                    >
                      {navigatingCourses[course.id] ? (
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#FF9933]"></div>
                      ) : (
                        <>
                          <span className="relative z-10 text-[#FF9933] group-hover:text-white transition-colors duration-300 text-[13px] font-[500] leading-[24px] ">
                            LEARN MORE
                          </span>
                          <span className="absolute inset-0 bg-[#FF9933] transform -translate-y-full group-hover:translate-y-0 transition-transform duration-300"></span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </motion.div>
            ))
          ) : (
            <div className="col-span-full text-center text-gray-100 py-8">
              No courses found matching your search.
            </div>
          )}
        </div>

        {/* Load More Button */}
        {hasMore && (
          <div className="flex justify-center mt-12">
            <button
              onClick={handleLoadMore}
              disabled={isLoading}
              className="w-full max-w-[250px] flex justify-center items-center bg-[#f08e2c] text-white rounded-full hover:bg-[#f08e2c]/90 transition-transform duration-300 ease-in-out transform hover:translate-y-1 hover:scale-105 py-4"
            >
              {isLoading ? (
                <>
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                  <span>Loading...</span>
                </>
              ) : (
                "Load More Courses"
              )}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default CoursesSection;
