import React from "react";
import { motion } from "framer-motion";
import {
  FaGraduationCap,
  FaUserFriends,
  FaChalkboardTeacher,
} from "react-icons/fa";

const HeroSection: React.FC = () => {
  // Animation variants for container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  // Animation variants for each stat box
  const statVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
  };

  // Animation variants for icons
  const iconVariants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 10,
      },
    },
  };

  // Animation variants for numbers
  const numberVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.nav
      className="bg-[#f48b14] mt-7"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.8 }}
    >
      <div className="px-4 sm:px-10">
        <div className="h-auto py-8 sm:h-64 flex items-center justify-center">
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-3 gap-8 text-white text-center"
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <motion.div
              className="flex flex-col items-center mb-6 sm:mb-0"
              variants={statVariants}
              whileHover={{ scale: 1.05 }}
            >
              <motion.div variants={iconVariants}>
                <FaGraduationCap className="text-4xl sm:text-5xl mb-3" />
              </motion.div>
              <motion.h3
                className="text-xl font-bold mb-2"
                variants={numberVariants}
              >
                100+
              </motion.h3>
              <motion.p className="text-sm" variants={numberVariants}>
                Classes Course
              </motion.p>
            </motion.div>

            <motion.div
              className="flex flex-col items-center mb-6 sm:mb-0"
              variants={statVariants}
              whileHover={{ scale: 1.05 }}
            >
              <motion.div variants={iconVariants}>
                <FaUserFriends className="text-4xl sm:text-5xl mb-3" />
              </motion.div>
              <motion.h3
                className="text-xl font-bold mb-2"
                variants={numberVariants}
              >
                10K
              </motion.h3>
              <motion.p className="text-sm" variants={numberVariants}>
                Active Member
              </motion.p>
            </motion.div>

            <motion.div
              className="flex flex-col items-center"
              variants={statVariants}
              whileHover={{ scale: 1.05 }}
            >
              <motion.div variants={iconVariants}>
                <FaChalkboardTeacher className="text-4xl sm:text-5xl mb-3" />
              </motion.div>
              <motion.h3
                className="text-xl font-bold mb-2"
                variants={numberVariants}
              >
                200+
              </motion.h3>
              <motion.p className="text-sm" variants={numberVariants}>
                Professional Instructors
              </motion.p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.nav>
  );
};

export default HeroSection;
